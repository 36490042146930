import { reactive } from "vue";

import { Translations } from "@horizon56/translations";
import { cloneDeep } from "@horizon56/utils";

import { master } from "@/infrastructure/translations/master";

const config = {
  locale: "en-US",
  translations: master,
};

export const translations = reactive<typeof config.translations>(cloneDeep(config.translations));

export const initiate = () => {
  new Translations<typeof config.translations>(config, [], translations);
};
