export const master = {
  buttons: {
    dropdownButton: {
      loading: "Loading...",
      success: "Done",
      failure: "Update failure",
    },
    moreOptions: "More",
    moreIndicator: {
      cancel: "Cancel",
    },
  },
  errorBanner: {
    message: "Not able to load content!",
    tryAgain: "Try again",
    autoRetry: "({n}s)",
  },
  tabs: {
    close: "Close tab",
    closeSpecific: "Close {n} tab",
  },
  linkedTo: {
    title: "Linked to",
  },
  inputs: {
    notSpecified: "Not specified",
    clear: "Clear",
    deselect: "Deselect all",
    select: {
      noMatches: "No suggestions found",
      add: "Press enter to add new",
    },
  },
  roleTag: {
    responsible: "Responsible",
    participants: "Participants",
    binding: "and",
  },
  duration: {
    notSpecified: "Not specified",
    days: "d",
    hours: "h",
    minutes: "m",
  },
  datetime: {
    cancel: "Cancel",
    save: "Save",
    time: "Time",
    date: "Date",
    notSpecified: "Not specified",
    monthBanner: {
      prev: "Previous month",
      next: "Next month",
      current: "Go to current month",
    },
  },
  filterChips: {
    showLess: "Show less",
    clearAll: "Clear all",
    more: "+ {n} more",
    remove: "Remove",
  },
  limitedList: {
    loadMore: "Show more",
  },
  truncatedList: {
    showMore: "More",
    showLess: "Less",
  },
  dialog: {
    edit: {
      title: "Edit",
    },
    delete: {
      title: "Delete",
      actions: {
        cancel: "Cancel",
        confirm: "Delete",
      },
    },
    confirm: {
      actions: {
        cancel: "Cancel",
        submit: "Submit",
      },
    },
  },
  pagination: {
    previous: "Previous page",
    dialogTitle: "Go to page",
    next: "Next page",
    submit: "Update",
    page: "Page",
    warning: "Page nr has to be between 1 and {n}",
  },
  modalBox: {
    close: "Close",
  },
  comments: {
    now: "Now",
    edit: "Edit",
    delete: "Delete",
    deleteInfo: {
      intro: "Are you sure you want to delete comment?",
      regret: "This action cannot be undone.",
    },
  },
  planListing: {
    time: {
      start: "Exp. start: {n}",
      end: "Exp. end: {n}",
    },
  },
  tooltip: {
    shortcutPrefix: "Press",
  },
  itemSnippet: {
    active: "Active",
    started: "Started",
    approved: "Approved",
    draft: "Draft",
    paused: "Paused",
    running: "Running",
    parallel: "Parallel",
  },
  accordionSection: {
    collapse: "Collapse",
    expand: "Expand",
  },
  accordionGroup: {
    collapse: "Collapse all",
    expand: "Expand all",
  },
};
